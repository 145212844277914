import generalStyles from '@/components/General/Styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import csrPermissionsData from './csr_permissions.json';

export function CSRPermissionsReport() {
    const [reportResult, setReportResult] = useState([]);

    const csrPermissionsListColumns = useMemo(
        () => [
            {
                Header: 'Menu section',
                accessor: 'Menu section',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Page',
                accessor: 'Page',
                id: 'page',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Feature',
                accessor:'Feature',
                id: 'feature',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Sales',
                accessor:'Sales',
                id: 'sales',
                className: generalStyles.tableCell
            }, 
            {
                Header: 'CSR',
                accessor:'CSR',
                id: 'csr',
                className: generalStyles.tableCell
            }, 
            {
                Header: 'Superuser',
                accessor:'Superuser',
                id: 'superuser',
                className: generalStyles.tableCell
            },
        ],
        []
    );

    useEffect(() => {
        setReportResult(csrPermissionsData);
    }, []);

    return (
        <>
            <h1>CSR Permissions</h1>
            <hr />
            <div data-test="generate-report-status">
                <DataTable
                    data={reportResult}
                    columns={csrPermissionsListColumns}
                    defaultPageSize={100}
                    withDownload
                />
            </div>
        </>
    );
}
