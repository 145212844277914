import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import Loader from '@/components/Spin';
import generalStyles from '@/components/General/Styles.module.scss';
import { getMatchingCompanies, getCompanyContracts, moveToTestAccount } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import styles from '@/components/ContractEndDateManagement/ContractEndDateForm.module.scss';

const MoveToTestAccount = () => {
    const [apiStates, setApiStates] = useState({
        companies: {
            state: API_STATES.none,
            error: '',
        },
        contracts: {
            state: API_STATES.none,
            error: '',
        },
        moveToTestAccount: {
            state: API_STATES.none,
            error: '',
        },
    });
    const [contractOptions, setContractOptions] = useState([]);
    const [formState, setFormState] = useState({
        sourceCompany: null,
        contractId: null,
    });

    const loadCompanies = async (companySearchTerm, callback) => {
        if (companySearchTerm === '') {
            setApiStates({ ...apiStates, companies: { state: API_STATES.none, error: '' } });
            callback([]);
            return;
        }
        setApiStates({ ...apiStates, companies: { state: API_STATES.loading, error: '' } });
        const response = await getMatchingCompanies(companySearchTerm);
        if (response.status === API_STATES.success) {
            const companyData = response.data;
            callback(
                companyData.sort().map((company) => {
                    return { label: company.name, value: company.id };
                })
            );
            setApiStates({ ...apiStates, companies: { state: API_STATES.success, error: '' } });
        } else {
            setApiStates({ ...apiStates, companies: { state: API_STATES.error, error: response.error.message } });
            callback([]);
        }
    };

    const loadCompaniesDebounced = debounce(loadCompanies, 1000);

    const loadContracts = async (companyId) => {
        if (!companyId) {
            setApiStates((prev) => ({ ...prev, contracts: { state: API_STATES.none, error: '' } }));
            return;
        }

        setApiStates((prev) => ({ ...prev, contracts: { state: API_STATES.loading, error: '' } }));
        const response = await getCompanyContracts(companyId);

        if (response.status === API_STATES.success) {
            setApiStates((prev) => ({ ...prev, contracts: { state: API_STATES.success, error: '' } }));
            return response.data.map((contractId) => ({
                label: contractId,
                value: contractId,
            }));
        } else {
            setApiStates((prev) => ({
                ...prev,
                contracts: { state: API_STATES.error, error: response.error.message },
            }));
            return [];
        }
    };

    const isFormValid = () => {
        return formState.sourceCompany?.value && formState.contractId?.value;
    };

    const handleSubmit = async () => {
        if (!isFormValid()) {
            return;
        }
        setApiStates({ ...apiStates, moveToTestAccount: { state: API_STATES.loading, error: '' } });
        const response = await moveToTestAccount(formState.sourceCompany.value, formState.contractId.value);
        if (response.status === API_STATES.success) {
            setFormState({ sourceCompany: null, contractId: null });
            setApiStates({ ...apiStates, moveToTestAccount: { state: API_STATES.success, error: '' } });
        } else {
            setApiStates({
                ...apiStates,
                moveToTestAccount: { state: API_STATES.error, error: response.error.message },
            });
        }
    };

    useEffect(() => {
        const fetchContracts = async () => {
            if (formState.sourceCompany?.value) {
                const contracts = await loadContracts(formState.sourceCompany?.value);
                setContractOptions(contracts);
            } else {
                setContractOptions([]);
            }
        };

        fetchContracts();
    }, [formState.sourceCompany]);

    return (
        <div className={styles['form-wrapper']}>
            {apiStates.moveToTestAccount.state === API_STATES.loading && <Loader />}
            <div className={styles.formField}>
                <label className={styles.label}>Source Company</label>
                <AsyncSelect
                    cacheOptions
                    backspaceRemovesValue
                    loadOptions={loadCompaniesDebounced}
                    onChange={(value) => setFormState({ ...formState, sourceCompany: value })}
                    escapeClearsValue={false}
                    placeholder="Search for a company..."
                    className={styles.select}
                    classNamePrefix="react-select"
                    value={formState.sourceCompany}
                    noOptionsMessage={() =>
                        apiStates.companies.state === API_STATES.success
                            ? 'No companies match the search'
                            : 'Please enter company name to search'
                    }
                />
                {apiStates.companies.state === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{apiStates.companies.error}</span>
                )}
            </div>

            <div className={styles.formField}>
                <label className={styles.label}>Contract ID</label>
                <Select
                    classNamePrefix="react-select"
                    onChange={(value) => setFormState({ ...formState, contractId: value })}
                    options={apiStates.contracts.state === API_STATES.loading ? [] : contractOptions}
                    value={formState.contractId}
                    placeholder="Select a contract..."
                    className={styles.select}
                    isLoading={apiStates.contracts.state === API_STATES.loading}
                    isDisabled={!formState.sourceCompany}
                />
                {apiStates.contracts.state === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{apiStates.contracts.error}</span>
                )}
            </div>

            <div className={styles.formActions}>
                <button className={generalStyles.submitButton} onClick={handleSubmit} disabled={!isFormValid()}>
                    Submit
                </button>
            </div>
            {apiStates.moveToTestAccount.state === API_STATES.success && (
                <span className={generalStyles.successMessage}>Success!</span>
            )}
        </div>
    );
};

export default MoveToTestAccount;
