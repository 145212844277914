import React, { useState } from 'react';
import MoveToTestAccount from './MoveToTestAccount';
import MoveToOriginalAccount from './MoveToOriginalAccount';
import styles from '@/components/ContractEndDateManagement/ContractEndDateManagement.module.scss';

const tabs = [
    {
        displayName: 'Move To Test Account',
        key: 'move-to-test',
    },
    {
        displayName: 'Move To Original Account',
        key: 'move-to-original',
    },
];

const ContractEndDateManagement = () => {
    const [activeTab, setActiveTab] = useState(0);

    const renderActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <MoveToTestAccount />;
            case 1:
                return <MoveToOriginalAccount />;
            default:
                return <h1>Default Tab</h1>;
        }
    };

    return (
        <>
            <div className={styles['tab-control']}>
                {tabs.map((dimension, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div
                                onClick={() => setActiveTab(index)}
                                className={`${styles['tab']} ${
                                    activeTab === index ? styles['tab-active'] : styles['tab-disabled']
                                }`}
                            >
                                <div className={styles['tab-text']}>
                                    <span>{dimension.displayName} </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            <div className="tab-control-content">{renderActiveTab()}</div>
        </>
    );
};

export default ContractEndDateManagement;
