import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import Loader from '@/components/Spin';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/ContractEndDateManagement/ContractEndDateForm.module.scss';
import {
    getMatchingCompanies,
    getTestCompanyContractIds,
    moveBackFromTestAccount,
} from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';

const MoveToOriginalAccount = () => {
    const [apiStates, setApiStates] = useState({
        companies: {
            state: API_STATES.none,
            error: '',
        },
        contracts: {
            state: API_STATES.none,
            error: '',
        },
        moveToOriginalAccount: {
            state: API_STATES.none,
            error: '',
        },
    });
    const [contractOptions, setContractOptions] = useState([]);
    const [formState, setFormState] = useState({
        originalCompany: null,
        contractId: null,
    });

    const loadCompanies = async (companySearchTerm, callback) => {
        if (companySearchTerm === '') {
            setApiStates({ ...apiStates, companies: { state: API_STATES.none, error: '' } });
            callback([]);
            return;
        }
        setApiStates({ ...apiStates, companies: { state: API_STATES.loading, error: '' } });
        const response = await getMatchingCompanies(companySearchTerm);
        if (response.status === API_STATES.success) {
            const companyData = response.data;
            callback(
                companyData.sort().map((company) => {
                    return { label: company.name, value: company.id };
                })
            );
            setApiStates({ ...apiStates, companies: { state: API_STATES.success, error: '' } });
        } else {
            setApiStates({ ...apiStates, companies: { state: API_STATES.error, error: response.error.message } });
            callback([]);
        }
    };

    const loadCompaniesDebounced = debounce(loadCompanies, 1000);

    const loadContracts = async () => {
        setApiStates((prev) => ({ ...prev, contracts: { state: API_STATES.loading, error: '' } }));
        const response = await getTestCompanyContractIds();
        if (response.status === API_STATES.success) {
            setApiStates((prev) => ({ ...prev, contracts: { state: API_STATES.success, error: '' } }));
            return response.data.map((contractId) => ({
                label: contractId,
                value: contractId,
            }));
        } else {
            setApiStates((prev) => ({
                ...prev,
                contracts: { state: API_STATES.error, error: response.error.message },
            }));
        }
    };

    const isFormValid = () => {
        return formState.originalCompany?.value && formState.contractId?.value;
    };

    const handleSubmit = async () => {
        if (!isFormValid()) {
            return;
        }
        setApiStates({ ...apiStates, moveToOriginalAccount: { state: API_STATES.loading, error: '' } });
        const response = await moveBackFromTestAccount(formState.originalCompany.value, formState.contractId.value);
        if (response.status === API_STATES.success) {
            setContractOptions((val) => val.filter((contract) => contract.value !== formState.contractId.value));
            setFormState({ originalCompany: null, contractId: null });
            setApiStates({ ...apiStates, moveToOriginalAccount: { state: API_STATES.success, error: '' } });
        } else {
            setApiStates({
                ...apiStates,
                moveToOriginalAccount: { state: API_STATES.error, error: response.error.message },
            });
        }
    };

    useEffect(() => {
        const fetchContracts = async () => {
            const contracts = await loadContracts();
            setContractOptions(contracts);
        };

        fetchContracts();
    }, []);

    return (
        <div className={styles['form-wrapper']}>
            {apiStates.moveToOriginalAccount.state === API_STATES.loading && <Loader />}
            <div className={styles.formField}>
                <label className={styles.label}>Original Company</label>
                <AsyncSelect
                    cacheOptions
                    backspaceRemovesValue
                    loadOptions={loadCompaniesDebounced}
                    onChange={(value) => setFormState({ ...formState, originalCompany: value })}
                    escapeClearsValue={false}
                    placeholder="Search for a company..."
                    className={styles.select}
                    classNamePrefix="react-select"
                    value={formState.originalCompany}
                    noOptionsMessage={() =>
                        apiStates.companies.state === API_STATES.success
                            ? 'No companies match the search'
                            : 'Please enter company name to search'
                    }
                />
                {apiStates.companies.state === API_STATES.error && (
                    <div className={styles.error}>{apiStates.companies.error}</div>
                )}
            </div>

            <div className={styles.formField}>
                <label className={styles.label}>Contract ID</label>
                <Select
                    classNamePrefix="react-select"
                    onChange={(value) => setFormState({ ...formState, contractId: value })}
                    options={apiStates.contracts.state === API_STATES.loading ? [] : contractOptions}
                    value={formState.contractId}
                    placeholder="Select a contract..."
                    className={styles.select}
                    isLoading={apiStates.contracts.state === API_STATES.loading}
                />
                {apiStates.contracts.state === API_STATES.error && (
                    <div className={styles.error}>{apiStates.contracts.error}</div>
                )}
            </div>

            <div className={styles.formActions}>
                <button className={generalStyles.submitButton} onClick={handleSubmit} disabled={!isFormValid()}>
                    Submit
                </button>
            </div>
            {apiStates.moveToOriginalAccount.state === API_STATES.success && (
                <span className={generalStyles.successMessage}>Success!</span>
            )}
        </div>
    );
};

export default MoveToOriginalAccount;
