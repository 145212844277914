import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getCompanyMultiTenantSettings(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/multi_tenant_settings`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editCompanyMultiTenantSettings(nsCompanyId, isMultiTenant, agreementLink) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/multi_tenant_settings`, {isMultiTenant, agreementLink});
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

/**
    expected response data
    { 
        nsCompanyId, tenantId, name, accessLevel, isOboModeAllowed, isDisabled, 
        categories, omniCategories
    }
 */
export async function getCompanyConfig(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/config`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyBrands(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/brands`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setBrandsForCompany(nsCompanyId, brands) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/brands`, { brands });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getRunCredits(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/runcredits`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateRunCreditCount(nsCompanyId, id, count) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/runcredits/${id}`, { newCount: count });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getApprovedMarkets(nsCompanyId) {
    try {
        const response = await http.get(`/company/${nsCompanyId}/retailers`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addMarket(nsCompanyId, retailerId, confirmationUrl, isPartial, categoryKeys) {
    try {
        const addMarketResponse = await http.post(`/company/${nsCompanyId}/retailers/${retailerId}`, {
            confirmationUrl,
            isPartial,
            categoryKeys,
        });
        return { status: API_STATES.success, data: addMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editPriorApprovedMarket(nsCompanyId, retailerId, confirmationUrl, isPartial, categoryKeys) {
    try {
        const editPriorApprovedMarketResponse = await http.put(`/company/${nsCompanyId}/retailers/${retailerId}`, {
            confirmationUrl,
            isPartial,
            categoryKeys,
        });
        return { status: API_STATES.success, data: editPriorApprovedMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteApprovedMarket(nsCompanyId, retailerId) {
    try {
        const deleteApprovedMarketResponse = await http.delete(`/company/${nsCompanyId}/retailers/${retailerId}`);
        return { status: API_STATES.success, data: deleteApprovedMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getOmniApprovedMarkets(nsCompanyId) {
    try {
        const response = await http.get(`/company/${nsCompanyId}/omni_retailers`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addOmniMarket(nsCompanyId, omniRetailerId, confirmationUrl, isPartial, categoryKeys) {
    try {
        const addOmniMarketResponse = await http.post(`/company/${nsCompanyId}/omni_retailers/${omniRetailerId}`, {
            confirmationUrl,
            isPartial,
            categoryKeys,
        });
        return { status: API_STATES.success, data: addOmniMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editOmniPriorApprovedMarket(nsCompanyId, omniRetailerId, confirmationUrl, isPartial, omniCategoryKeys) {
    try {
        const editOmniPriorApprovedMarketResponse = await http.put(
            `/company/${nsCompanyId}/omni_retailers/${omniRetailerId}`,
            {
                confirmationUrl,
                isPartial,
                categoryKeys: omniCategoryKeys,
            }
        );
        return { status: API_STATES.success, data: editOmniPriorApprovedMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteOmniApprovedMarket(nsCompanyId, omniRetailerId) {
    try {
        const deleteOmniApprovedMarketResponse = await http.delete(
            `/company/${nsCompanyId}/omni_retailers/${omniRetailerId}`
        );
        return { status: API_STATES.success, data: deleteOmniApprovedMarketResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyUserConfig(nsCompanyId, nsUserId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/users/${nsUserId}/config`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function removeUserFromCompany(nsCompanyId, email) {
    try {
        const res = await http.delete(`/company/${nsCompanyId}/users`, { params: { email } });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getUserRemovalImpactCount(byzUserId, nsCompanyId){
    try {
        const res = await http.get(`/company/${nsCompanyId}/user_removal_impact/${byzUserId}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyUserDetails(nsCompanyId, nsUserId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/users/${nsUserId}/details`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
/**
 * updates the user id
 * @param {string|number} userId the nsUserId of user whose role should be updated
 * @param {Object} details the user details
 * @param {string | undefined} details.firstName the firstName of user
 * @param {string | undefined} details.lastName the lastName of user
 * @param {'admin' | 'user' | 'viewer'} details.role the new role of user
 * @param {number} details.teamId the new teamId of user
 * @returns
 */
export async function updateCompanyUserDetails(nsCompanyId, nsUserId, details) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/users/${nsUserId}/details/`, details);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanySearchList(companyName) {
    try {
        const res = await http.get(`/company?search=${companyName}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyAccessibleMarkets(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/accessible_markets`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyAccessibleMarketsCount(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}/accessible_markets/allowed_count`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function setAccessibleMarketsForCompany(nsCompanyId, markets) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/accessible_markets`, { markets });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function getTrialAddonsByCompany(nsCompanyId) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/trial_products`);
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function getPreSaleStatus(nsContractId) {
    try {
        const result = await http.get(`/company/${nsContractId}/preSaleStatus`);
        return { status: API_STATES.success, data: result.data }; ;
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function moveContractToActive(nsContractId) {
    try {
        await http.put(`/company/${nsContractId}/preSaleStatus`);
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function createNewCompany(
    companyName,
    companyType,
    companyDomain,
    alternateDomains,
    email,
    phone,
    creditLimit,
    address,
    upliftPercentage,
    accessLevel,
    dynamicDealNum
) {
    try {
        const res = await http.post(`/company`, {
            companyName,
            companyType,
            companyDomain,
            alternateDomains,
            email,
            phone,
            creditLimit,
            address,
            upliftPercentage,
            accessLevel,
            dynamicDealNum
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateCompany(nsCompanyId, addedDomains, email, phone, upliftPercentage, address, accessLevel, dynamicDealNum) {
    try {
        const res = await http.put(`/company/${nsCompanyId}/`, {
            addedDomains,
            email,
            phone,
            upliftPercentage,
            address,
            accessLevel,
            dynamicDealNum
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllCompanyTypes() {
    try {
        const res = await http.get(`/company/types`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getMatchingCompanies(search) {
    try {
        const res = await http.get(`/company/names`, { params: { search } });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function purchaseProducts(nsCompanyId, newItems, contractOptions) {
    try {
        await http.post(`/company/${nsCompanyId}/purchase_products`, { newItems, contractOptions });
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getTeamsByCompanyId(nsCompanyId) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/teams`);
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function inviteUser(email, firstName, lastName, role, teamId, nsCompanyId, domainMismatchReasonCode = null) {
    try {
        const result = await http.post(`/company/${nsCompanyId}/invitation`, { email, firstName, lastName, role, teamId, domainMismatchReasonCode });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function validateInvitationDomain(nsCompanyId, email) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/invitation/validate_domain`, { params: { email } });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function inviteConsultant(email, role, teamId, nsCompanyId) {
    try {
        await http.post(`/company/${nsCompanyId}/invitation/consultant`, { email, role, teamId });
        return { status: API_STATES.success, data: true };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function resendInvitationEmail(email, nsCompanyId) {
    try {
        await http.put(`/company/${nsCompanyId}/invitation`, { email });
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getInvitationLink(email, nsCompanyId) {
    try {
        const response = await http.get(`/company/${nsCompanyId}/invitation_link`,{ params: { email } });
        return { status: API_STATES.success, data: response.data};
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function publishBilling(
    email,
    contact,
    address,
    state,
    city,
    zipcode,
    selectedBillingFrequency,
    selectedBScopy,
    nsCompanyId
) {
    try {
        const result = await http.post(`/company/${nsCompanyId}/billing`, {
            email,
            contact,
            address,
            state,
            city,
            zipcode,
            selectedBillingFrequency,
            selectedBScopy
        });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateCompanyIsOboModeAllowedStatus(nsCompanyId, isOboModeAllowed) {
    try {
        const result = await http.patch(`/company/${nsCompanyId}/obo_mode`, { isOboModeAllowed });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error };
    }
}

export async function updateDisableCompanyStatus(nsCompanyId, disableCompanyStatus){
    try {
        const result = await http.post(`/company/${nsCompanyId}/disable`, { status: disableCompanyStatus });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error };
    }    
}

export async function getAllCompanies() {
    try {
        const res = await http.get(`message/companylist`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllUsers(companyList) {
    try {
        const result = await http.post(`/message/userslist`, { companyList });
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getNsCompanyData(nsCompanyId) {
    try {
        const res = await http.get(`/company/${nsCompanyId}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getPaymentTermOptions(){
    try {
        const res = await http.get(`/company/paymentTerms`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setCategories(nsCompanyId, categories) {
    try {
        const response = await http.put(`/company/${nsCompanyId}/categories`, { categories });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function generateInternalAdminCredentials(nsCompanyId, companyName) {
    try {
        const res = await http.post(`/company/${nsCompanyId}/setup_admin`, { companyName });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteInternalAdminUser(nsCompanyId) {
    try {
        const res = await http.delete(`/company/${nsCompanyId}/setup_admin`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setOmniCategories(nsCompanyId, categories) {
    try {
        const response = await http.put(`/company/${nsCompanyId}/omni_categories`, { categories });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllTeamMembersByCompanyId(nsCompanyId) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/team_members`);
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getQuoteIds(nsCompanyId){
    try {
        const result = await http.get(`/company/${nsCompanyId}/quotes`)
        return { status: API_STATES.success, data: result.data };
    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function getAttachedQuoteUsers(nsCompanyId){
    try {
        const result = await http.get(`/company/${nsCompanyId}/quoteStatus`)
        return { status: API_STATES.success, data: result.data };
    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function attachSelectedQuote(users, quoteId, nsCompanyId){
    try {
        const result = await http.post(`/company/${nsCompanyId}/quote`, {
            users,
            quoteId,
        })
        return { status: API_STATES.success, data: result.data };
    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function editSelectedQuote(users, quoteId, nsCompanyId){
    try{
        const result = await http.put(`/company/${nsCompanyId}/quotes`, {
            users,
            quoteId,
        })
        return { status: API_STATES.success, data: result.data };
    }catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteAttachedQuoteUsers(nsCompanyId){
    try{
        const result = await http.delete(`/company/${nsCompanyId}/quotes`)
        return { status: API_STATES.success, data: result.data };
    }catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function closeCompanyContract(nsCompanyId, contractId){
    try{
        const result = await http.delete(`/company/${nsCompanyId}/contract/${contractId}`)
        return { status: API_STATES.success, data: result.data };
    }catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyList(){
    try{
        const result = await http.get(`csr_api/company/mya_contracts`)
        const formattedData = result?.data.map((item)=>(
            {
                companyId: item?.company_id,
                companyName: item?.company_name,
                contractId: item?.contract_id,
                mya: item?.mya === 'T'? 'Y':'N',
                activeSubscriptionEndDate: item?.active_subscription_contract_end_date,
                trueContractEndDate: item?.true_contract_end_date,
                activeSubscriptionStartDate: item?.active_subscription_contract_start_date
            }
        ))
        return { status: API_STATES.success, data: formattedData };
    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function getItemsData(nsCompanyId){
    try{
        const result  = await http.get(`csr_api/company/contract/${nsCompanyId}`)
        return { status: API_STATES.success, data: result?.data };

    } catch(error){
        return { status: API_STATES.error, error: error };
    }

}

export async function updateByzzerAccessEndDate(nsCompanyId, byzzerAccessEndDate){
    try{
        const result = await http.put(`/company/${nsCompanyId}/access_end_date`, {
            "endDate": byzzerAccessEndDate
        })
        return { status: API_STATES.success, data: result?.data };

    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function getByzzerAccessEndDate(nsCompanyId){
    try{
        const result = await http.get(`/company/${nsCompanyId}/access_end_date`)
        return { status: API_STATES.success, data: result?.data };

    } catch(error){
        return { status: API_STATES.error, error: error };
    }
}

export async function getMyaDetails(nsCompanyId) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/mya`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function addMultiYearAgreement(nsCompanyId, trueContractEndDate) {
    try {
        const result = await http.post(`/company/${nsCompanyId}/mya`, { trueContractEndDate })
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateTrueContractEndDate(nsCompanyId, trueContractEndDate) {
    try {
        const result = await http.put(`/company/${nsCompanyId}/mya`, { trueContractEndDate })
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function terminateMultiYearAgreement(nsCompanyId, trueContractEndDate) {
    try {
        const result = await http.delete(`/company/${nsCompanyId}/mya`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyContracts(nsCompanyId) {
    try {
        const result = await http.get(`/company/${nsCompanyId}/contractIds`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getTestCompanyContractIds() {
    try {
        const result = await http.get(`/company/test_company/contractIds`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}


export async function moveToTestAccount(nsCompanyId, nsContractId) {
    try {
        const result = await http.put(`/company/${nsCompanyId}/contract/${nsContractId}/move_to_test_account`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function moveBackFromTestAccount(nsCompanyId, nsContractId) {
    try {
        const result = await http.put(`/company/${nsCompanyId}/contract/${nsContractId}/move_back_from_test_account`)
        return { status: API_STATES.success, data: result?.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}