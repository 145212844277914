import classNames from 'classnames';
import { useState } from 'react';
import { DashboardNavItem } from './DashboardNavItem';
import './DashboardNav.scss';

import companyIcon from '@images/icons/fidget-white.svg';
import speakerIcon from '@images/icons/speaker-white.svg';
import noteIcon from '@images/icons/note-white.svg';
import mailIcon from '@images/icons/mail-white.svg';
import pieChartIcon from '@images/icons/pie-chart-white.svg';
import platformIcon from '@images/icons/platform-white.svg';
import calendarIcon from '@images/icons/calendar.svg';
import mergeVectorIcon from '@images/icons/merge-svg-outlined.svg';
import CSR_ROLES from '@/constants/CsrRoles';

const baseClassName = 'dashboard-nav';

export function DashboardNav({ disabled = false }) {

    const [collapsed, setCollapsed] = useState(false);
    const allMenuItems = [
        {
            content: 'Company',
            type: 'company',
            otherUrls: ['newcompanypage(/[-+]?\\d*)?','newcompanypage/(/[-+]?\\d+)/users/(/[-+]?\\d*)?'],
            title: 'Company',
            iconSrc: companyIcon,
            url: '/newcompanypage',
        },
        {
            content: 'Notifications',
            type: 'notifications',
            otherUrls: ['/notification/userbased', '/notification/categorybased', '/notification/marketbased', 'notification/subscriptionbased'],
            title: 'Notifications',
            iconSrc: mailIcon,
            accessibleBy: [CSR_ROLES.csr, CSR_ROLES.superUser],
            subItems: [
                {
                    content: 'User Based Notification',
                    type: 'user-based-notification',
                    url: '/notification/userbased',
                    title: 'User Based Notification',
                },
                {
                    content: 'Category Based Notification',
                    type: 'category-based-notification',
                    url: '/notification/categorybased',
                    title: 'Category Based Notification',
                },
                {
                    content: 'Market Based Notification',
                    type: 'market-based-notification',
                    url: '/notification/marketbased',
                    title: 'Market Based Notification',
                },
                {
                    content: 'Subscription Based Notification',
                    type: 'subscription-based-notification',
                    url: '/notification/subscriptionbased',
                    title: 'Subscription Based Notification',
                },
            ],
        },
        {
            content: 'Reports',
            type: 'reports',
            otherUrls: ['/reportrunsreport', '/unusedcreditreport', '/userstatusreport', '/categorylicensereport', '/reportrunsselections','/accessenddatereport', '/userlevelreport','/invitationapprovalreport'],
            title: 'Reports',
            iconSrc: pieChartIcon,
            subItems: [
                {
                    content: 'CSR Audit Logs Report',
                    type: 'csr-audit-logs',
                    url: '/csrauditlogsreport',
                    title: 'CSR Audit Logs Report',
                },
                {
                    content: 'Report Runs History',
                    type: 'report-runs-history',
                    url: '/reportrunsreport',
                    title: 'Report Runs History',
                },
                {
                    content: 'Unused Credit By Company',
                    type: 'unused-credits-report',
                    url: '/unusedcreditreport',
                    title: 'Unused Credit By Company',
                },
                {
                    content: 'User Status By Company',
                    type: 'user-status-report',
                    url: '/userstatusreport',
                    title: 'User Status By Company',
                },
                {
                    content: 'Byzzer Access End Date Report',
                    type: 'access-end-date-report',
                    url: '/accessenddatereport',
                    title: 'Byzzer Access End Date Report',
                },
                {
                    content: 'Category Licensed Report',
                    type: 'category-license-report',
                    url: '/categorylicensereport',
                    title: 'Category Licensed Report',
                },
                {
                    content: 'Run History With User Selections Filters',
                    type: 'report-runs-with-user-selections',
                    url: '/reportrunsselections',
                    title: 'Run History With User Selection Filters',
                },
                // {
                //     content: 'Report Run with User Selections ',
                //     type: 'user-selection-report-old',
                //     url: '/reportrunsselectionsold',
                //     title: 'Report Run with User Selections',
                // },
                {
                    content: 'Approved Markets Report',
                    type: 'approved-markets-report',
                    url: '/approvedmarketsreport',
                    title: 'Approved Markets Report',
                },
                {
                    content: 'Demo Accounts Report',
                    type: 'demo-accounts-report',
                    url: '/demoaccountsreport',
                    title: 'Demo Accounts Report',
                },
                {
                    content: 'Brand Report',
                    type: 'brand-report',
                    url: '/brandreport',
                    title: 'Brand Report',
                },
                {
                    content: 'Free To Paid Tracker',
                    type: 'free-to-paid-tracker',
                    url: '/freetopaidtracker',
                    title: 'Free To Paid Tracker',
                },
                {
                    content: 'Domains Invitation Report',
                    type: 'domains-Invitation-report',
                    url: '/domaininvitationreport',
                    title: 'Domains Invitation Report',
                },
                {
                    content: 'Invitation Approval Report',
                    type: 'invitation-approval-report',
                    url: '/invitationapprovalreport',
                    title: 'Invitation Approval Report',
                },
                {
                    content: 'User Level Report',
                    type: 'user-level-report',
                    url: '/userlevelreport',
                    title: 'User Level Report'
                },
                {                
                    content: 'Category Change Events',
                    type: 'category-change-events',
                    url: '/categorychangeevents',
                    title: 'Category Change Events',
                },
                {
                    content: 'CSR Permissions List',
                    type: 'csr-permissions-list',
                    url: '/csrpermissionsreport',
                    title: 'CSR Permissions List',
                },
            ],
        },
        {
            content: 'Sales & Marketing',
            type: 'sales-marketing',
            otherUrls: ['/ondemandalerts', '/ondemandsinglealert', '/ondemandalertsstatus', '/ondemandalertdetails'],
            title: 'Sales & Marketing',
            iconSrc: speakerIcon,
            subItems: [
                {
                    content: 'On Demand Marketing Alerts',
                    type: 'on-demand-alerts',
                    url: '/ondemandalerts',
                    title: 'On Demand Marketing Alerts',
                },
                {
                    content: 'Single On Demand Marketing Alert',
                    type: 'on-demand-single-alert',
                    url: '/ondemandsinglealert',
                    title: 'Single On Demand Marketing Alert',
                },
                {
                    content: 'On Demand Marketing Alerts Status',
                    type: 'on-demand-alerts-status',
                    url: '/ondemandalertsstatus',
                    title: 'On Demand Marketing Alerts Status',
                },
            ],
        },
        {
            content: 'Company Setup',
            type: 'company-setup',
            url: '/companysetup',
            otherUrls: [],
            title: 'Company Setup',
            iconSrc: noteIcon,            
            accessibleBy: [CSR_ROLES.csr, CSR_ROLES.superUser],
        },
        {
            content: 'MYA Company Setup',
            type: 'mya-company-setup',
            url: '/mya-companysetup',
            otherUrls: [],
            title: 'MYA Company Setup',
            iconSrc: noteIcon,            
            accessibleBy: [CSR_ROLES.csr, CSR_ROLES.superUser],
        },
        {
            content: 'Bundle Manager',
            type: 'bundle-manager',
            url: '/bundlemanager',
            otherUrls: [],
            title: 'Bundle Manager',
            iconSrc: platformIcon,
            accessibleBy: [CSR_ROLES.superUser],
        },
        {
            content: 'Market Maps',
            type: 'market-maps',
            url: '/marketmaps',
            otherUrls: [],
            title: 'Market Maps',
            iconSrc: platformIcon,
            accessibleBy: [CSR_ROLES.superUser],
            subItems: [
                {
                    content: 'Retailer Maps',
                    type: 'retailer-market-maps',
                    url: '/marketmaps/retailer',
                    title: 'Retailer Market Maps',
                },
                {
                    content: 'Syndicated Maps',
                    type: 'syndicate-market-maps',
                    url: '/marketmaps/syndicate',
                    title: 'Syndicated Market Maps',
                },
            ],
        },
        {
            content: 'Category Change',
            type:'category-management',
            url: '/categorymanagement',
            otherUrls: [],
            title: 'Category Change',
            iconSrc: mergeVectorIcon,
            accessibleBy: [CSR_ROLES.superUser],
            subItems: [
                {
                    content: 'Consolidate Category',
                    type: 'consolidate category',
                    url: '/categorymanagement/consolidatecategory',
                    title: 'Consolidate Category',
                },
                {
                    content: 'Split up Category',
                    type: 'Splitup category',
                    url: '/categorymanagement/splitupcategory',
                    title: 'Splitup Category',
                }
            ]
        },
        {
            content: 'Contract End Date Management',
            type: 'contract-end-date-management',
            url: '/contract-end-date-management',
            otherUrls: [],
            title: 'Contract End Date Management',
            iconSrc: calendarIcon,
            accessibleBy: [CSR_ROLES.superUser],
        },
    ];

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    return (
        <div
            className={classNames(baseClassName, {
                [`${baseClassName}--collapsed`]: collapsed,
            })}
        >
            <header className={`${baseClassName}__header`} />
            <main className={`${baseClassName}__content`}>
                {allMenuItems.map(
                    ({ topHeaderItem, ...props }) =>
                        !topHeaderItem && (
                            <DashboardNavItem
                                key={props.content}
                                disabled={disabled}
                                collapsed={collapsed}
                                {...props}
                            />
                        )
                )}
            </main>

            <div className={`${baseClassName}__collapse-trigger`} onClick={toggleCollapsed} />
        </div>
    );
}
