import './App.scss';
import React, { useEffect, useState, createContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import { firebaseConfig } from './config';
import { getUser } from './services/account.service';
import store from './store';
import { GuardProvider, GuardedRoute } from 'react-router-guards';

import GetResetPasswordLink from '@/components/GetResetPasswordLink/GetResetPasswordLink';
import BundleManager from '@/components/BundleManager/BundleManager';
import UserStatusReport from '@/components/UserStatusReport/UserStatusReport';
import UserLevelReport from './components/UserLevelReport/UserLevelReport';
import UnusedCreditReport from '@/components/UnusedCreditReport/UnusedCreditReport';
import CompanySearch from '@/components/CompanySearch/CompanySearch';
import CreateNewCSR from '@/components/CreateNewCSR/CreateNewCSR';
import ReportRunsReport from '@/components/ReportRunsReport/ReportRunsReport';
import ReportRunsWithSelections from '@/components/ReportRunsWithSelections/ReportRunsWithSelections';
// import ReportRunsWithSelectionsOld from '@/components/ReportRunsWithSelections/ReportRunsWithSelectionsOld';
import Signin from '@/components/Signin';
import RunCredits from '@/components/ContractItemInstances/RunCredits';
import ChangePassword from '@/components/Operations/ChangePassword';
import ReportDashboard from '@/components/Reports/ReportDashboard';
import { Logout } from '@/components/Operations/Logout';
import Maintenance from './components/Maintenance/Maintenance';
import AlertsStatus from '@/components/OnDemandAlerts/AlertsStatus';
import OnDemandAlerts from './components/OnDemandAlerts/OnDemandAlerts';
import ModifyCSR from '@/components/ModifyCSR/ModifyCSR';
import ModifySpecificCsr from '@/components/ModifyCSR/ModifySpecificCsr';
import { OnDemandSingleAlert } from './components/OnDemandAlerts/OnDemandSingleAlert';
import OboActionsByCompany from './components/OboActionsByCompany/OboActionsByCompany';
import CategoryLicenseReport from '@/components/CategoryLicenseReport/CategoryLicenseReport';
import AlertDetails from './components/OnDemandAlerts/AlertDetails';
import AccountSetup from '@/components/AccountSetup/AccountSetup';
import MultiYearContractCompanySetup from './components/MultiYearContractCompanySetup/MultiYearContractCompanySetup';
import ScheduleDataRefreshMaintenance from './components/DataRefreshMaintenance/ScheduleDataRefreshMaintenance';
import DataMaintenanceSchedules from './components/DataRefreshMaintenance/DataMaintenanceSchedules';
import Editdatamaintenanceschedule from './components/DataRefreshMaintenance/EditDataMaintenanceSchedule';
import UserBasedNotification from './components/HomepageNotification/UserBased/UserBasedNotification';
import CategoryBasedNotification from './components/HomepageNotification/CategoryBased/CategoryBasedNotification';
import MarketBasedNotification from './components/HomepageNotification/MarketBased/MarketBasedNotification';
import SubscriptionBasedNotification from './components/HomepageNotification/SubscriptionBased/SubscriptionBasedNotification';
import { DashboardNav } from './components/Dashboard/DashboardNav';
import DashboardHeader from './components/Dashboard/DashboardHeader';
import NewCompanyPage from './components/NewCompanyPage/NewCompanyPage';
import DashboardContent from './components/Dashboard/DashboardContent';
import CSR_ROLES from '@/constants/CsrRoles';
import { useMemo } from 'react';
import ApprovedMarketsReport from './components/ApprovedMarketsReport/ApprovedMarketsReport';
import { DemoAccountsReport } from './components/DemoAccountsReport/DemoAccountsReport';
import { CSRPermissionsReport } from './components/CSRPermissionsReport/CSRPermissionsReport';
import RetailerMarketMaps from './components/MarketMaps/Retailer/RetailerMarketMaps';
import SyndicateMarketMaps from './components/MarketMaps/Syndicate/SyndicateMarketMaps';
import { BrandReport } from './components/BrandReport/BrandReport';
import FreeAccountsReport from './components/FreeToPaidTracker/FreeToPaidTracker'
import ConsolidateCategory from './components/CategoryManagement/ConsolidateCategory';
import SplitupCategory from './components/CategoryManagement/SplitupCategory';
import DomainsInvitationReport from './components/DomainsInvitationReport/DomainsInvitationReport';
import InvitationApprovalReport from './components/InvitationApprovalReport/InvitationApprovalReport';
import CategoryChangeEvents from './components/CategoryChangeEvents/CategoryChangeEvents'
// import { DashboardNav } from './components/Dashboard/DashboardNav';
import ByzzerAccessEndDateReport from './components/ByzzerAccessEndDateReport/ByzzerAccessEndDateReport';
import { CsrAuditLogsReport } from './components/CsrAuditLogsReport/CsrAuditLogsReport';
import ContractEndDateManagement from './components/ContractEndDateManagement/ContractEndDateManagement';

export const companyUserSearchContext = createContext({
    nsUserId: 0,
    nsCompanyId: 0,
    companyName: '',
    companyAccessLevel: '',
    selectedTab: 0,
});

function App() {
    const [initializing, setInitializing] = useState(true);

    const location = useLocation();
    const [storeState, setStoreState] = useState(store?.getState());
    useEffect(() => {
        return store.subscribe(() => {
            setStoreState(store.getState());
        });
    }, []);
    const needToResetPassword = useMemo(() => {
        return storeState?.account?.user?.resetPassword;
    }, [storeState]);

    /** Vaild login is required to process the route else redirect to signin page */
    const requireLogin = (to, from, next) => {
        if (!store?.getState()?.account?.isLoggedIn) {
            next.redirect('/signin');
        }
        next();
    };

    /** If resetPassword flag is set, redirect to changePassword page else route to requested page */
    const checkResetPassword = (to, from, next) => {
        if (needToResetPassword) {
            next.redirect('/changepassword');
        }
        next();
    };

    /**
     * at least one role should be provided as argument
     * otherwise the link will be accessible by all roles
     */
    const accessibleBy = (...accessibleRoles) => {
        const accessGuard = (to, from, next) => {
            if (accessibleRoles?.length) {
                const roles = store?.getState()?.account?.user?.csrUserRoles;
                const matchingRole = accessibleRoles.find((requiredRole) => roles?.includes(requiredRole));
                if (!matchingRole) {
                    next.redirect('/newcompanypage');
                }
            }
            next();
        };
        return accessGuard;
    };

    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
            const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    store.dispatch.account.setUser(await getUser());
                } else {
                    store.dispatch.account.clearUser();
                }
                setInitializing(false);
                unsubscribe();
            });
        }
    }, []);

    if (initializing) {
        return (
            // todo: create an app initializing view
            <div className={'App App--initializing'}>Preparing Byzzer</div>
        );
    } else if (location.pathname === '/signin') {
        return <Signin />;
    } else {
        return (
            <div className="App">
                {/* enclosing frame */}

                <div className="dashboard">
                    <DashboardNav disabled={needToResetPassword} />
                    <DashboardHeader disabled={needToResetPassword} />
                    <DashboardContent>
                        <GuardProvider guards={[requireLogin]}>
                            <Switch>
                                {/* add top-level routes here */}
                                {/* everything other than signin should be private */}
                                <Route path="/signin">
                                    <Signin />
                                </Route>
                                <Route path="/logout">
                                    <Logout />
                                </Route>

                                <GuardedRoute exact path="/changepassword" component={ChangePassword} guards={[]} />

                                <GuardedRoute
                                    exact
                                    path="/companysearch"
                                    component={CompanySearch}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/generateresetpasswordlink"
                                    component={GetResetPasswordLink}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/newcompanypage/:nsCompanyId?"
                                    component={NewCompanyPage}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/newcompanypage/:nsCompanyId/users/:nsUserId?"
                                    component={NewCompanyPage}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/runcredits/:email"
                                    component={RunCredits}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/obobycompany/:companyid"
                                    component={OboActionsByCompany}
                                    guards={[checkResetPassword]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/accessenddatereport"
                                    component={ByzzerAccessEndDateReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/reports"
                                    component={ReportDashboard}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/reportrunsreport"
                                    component={ReportRunsReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/reportrunsselections"
                                    component={ReportRunsWithSelections}
                                    guards={[checkResetPassword]}
                                />

                                {/* <GuardedRoute
                                    exact
                                    path="/reportrunsselectionsold"
                                    component={ReportRunsWithSelectionsOld}
                                    guards={[checkResetPassword]}
                                /> */}

                                <GuardedRoute
                                    exact
                                    path="/categorylicensereport"
                                    component={CategoryLicenseReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/unusedcreditreport"
                                    component={UnusedCreditReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/userstatusreport"
                                    component={UserStatusReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/approvedmarketsreport"
                                    component={ApprovedMarketsReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/demoaccountsreport"
                                    component={DemoAccountsReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/csrauditlogsreport"
                                    component={CsrAuditLogsReport}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/brandreport"
                                    component={BrandReport}
                                    guards={[checkResetPassword]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/freetopaidtracker"
                                    component={FreeAccountsReport}
                                    guards={[checkResetPassword]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/userlevelreport"
                                    component={UserLevelReport}
                                    guards={[checkResetPassword]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/ondemandalerts"
                                    component={OnDemandAlerts}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/ondemandsinglealert"
                                    component={OnDemandSingleAlert}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/ondemandalertsstatus"
                                    component={AlertsStatus}
                                    guards={[checkResetPassword]}
                                />

                                    <GuardedRoute
                                        exact
                                        path="/brandreport"
                                        component={BrandReport}
                                        guards={[checkResetPassword]}
                                    />
                                    <GuardedRoute
                                        exact
                                        path="/freetopaidtracker"
                                        component={FreeAccountsReport}
                                        guards={[checkResetPassword]}
                                    />
                                    <GuardedRoute
                                        exact
                                        path="/domaininvitationreport"
                                        component={DomainsInvitationReport}
                                        guards={[checkResetPassword]}
                                    />
                                    <GuardedRoute
                                        exact
                                        path="/invitationapprovalreport"
                                        component={InvitationApprovalReport}
                                        guards={[checkResetPassword]}
                                    />

                                    <GuardedRoute
                                        exact
                                        path="/categorychangeevents"
                                        component={CategoryChangeEvents}
                                        guards={[checkResetPassword]}
                                    />

                                    <GuardedRoute
                                        exact
                                        path="/csrpermissionsreport"
                                        component={CSRPermissionsReport}
                                        guards={[checkResetPassword]}
                                    />
                                <GuardedRoute
                                    exact
                                    path="/ondemandalertdetails/:correlationId"
                                    component={AlertDetails}
                                    guards={[checkResetPassword]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/companysetup/:nsCompanyId?"
                                    component={AccountSetup}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/mya-companysetup"
                                    component={MultiYearContractCompanySetup}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/notification/userbased"
                                    component={UserBasedNotification}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/notification/categorybased"
                                    component={CategoryBasedNotification}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/notification/marketbased"
                                    component={MarketBasedNotification}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/notification/subscriptionbased"
                                    component={SubscriptionBasedNotification}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.csr, CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/bundlemanager"
                                    component={BundleManager}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/modifycsr"
                                    component={ModifyCSR}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/modifycsr/:email"
                                    component={ModifySpecificCsr}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/createnewcsr"
                                    component={CreateNewCSR}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/maintenance"
                                    component={Maintenance}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/scheduledatarefreshmaintenance"
                                    component={ScheduleDataRefreshMaintenance}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/editdatamaintenanceschedule/:scheduleId"
                                    component={Editdatamaintenanceschedule}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path="/datamaintenanceschedules"
                                    component={DataMaintenanceSchedules}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                    <GuardedRoute
                                    exact
                                    path="/marketmaps/syndicate"
                                    component={SyndicateMarketMaps}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />

                                <GuardedRoute
                                    exact
                                    path="/marketmaps/retailer"
                                    component={RetailerMarketMaps}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path = "/categorymanagement/consolidatecategory"
                                    component ={ConsolidateCategory}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path = "/categorymanagement/splitupcategory"
                                    component ={SplitupCategory}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />
                                <GuardedRoute
                                    exact
                                    path = "/contract-end-date-management"
                                    component ={ContractEndDateManagement}
                                    guards={[checkResetPassword, accessibleBy(CSR_ROLES.superUser)]}
                                />

                                <Route path="/*">
                                    <Redirect to="/newcompanypage" />
                                </Route>
                            </Switch>
                        </GuardProvider>
                    </DashboardContent>
                </div>
            </div>
        );
    }
}

export default App;
